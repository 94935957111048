"use client";
import React, { useEffect, useState, useCallback } from "react";
import HomepageCarousel from "@/components/Homepage/HomepageCarousel";
import ClientOnly from "@/components/common/ClientOnly";
import { getCatalogueTopIndianList } from "@/services/catalogueServices";
import FranchiseCard from "./FranchiseCard";
import SkeletonCard from "@/components/common/Skeleton/SkeletonCard/SkeletonCard";

const TopFranchises = () => {
  const [topIndianList, setTopIndianList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCatalogueTopList = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getCatalogueTopIndianList();
      if (response?.status === "Success") {
        setTopIndianList(response?.data);
      } else {
        setError("Failed to fetch data");
      }
    } catch (error) {
      setError("An error occurred while fetching data");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCatalogueTopList();
  }, [fetchCatalogueTopList]);
console.log("topIndianListtopIndianList",topIndianList)
  return (
    <div className="bg-primary">
      <div className="container mx-auto py-3 lg:py-8">
        {loading ? (
          <SkeletonCard />
        ) : (
          <ClientOnly>
            <HomepageCarousel
              title="Top Franchisors"
              subtitle=""
              dots={true}
              slidesToScroll={5}
              slidesToShow={5}
            >
              {topIndianList.map((el) => (
                <FranchiseCard key={el?._id} franchise={el} />
              ))}
            </HomepageCarousel>
          </ClientOnly>
        )}
      </div>
    </div>
  );
};

export default TopFranchises;
