import { getProfile } from "@/services/AuthServices";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getProfileData = createAsyncThunk(
  "authState/getProfileData",
  async () => {
    try {
      const response = await getProfile();
      // localStorage.setItem("profile", JSON.stringify(response?.data));
      return response?.data;
    } catch (error) {
      console.log("profile detch error", error);
      throw error;
    }
  }
);
