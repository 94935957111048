"use client";

import React, { useEffect, useState } from "react";
import HomepageCategoryImage from "@/assets/images/placeholder.jpeg";
import {
  getMasterCategoryList,
  getMasterCardList,
} from "@/services/CategoryListServices";
import Link from "next/link";
import "./HomeageCategory.scss";
import { createSlug } from "@/utils/helper";
import ImageThumbnail from "@/components/common/ImageThumbnail/ImageThumbnail";
import SkeletonCard from "@/components/common/Skeleton/SkeletonCard/SkeletonCard";

const HomepageCategory = () => {
  const [state, setState] = useState({
    masterCatlist: [],
    masterCardList: [],
    activeTab: null,
  });

  const [loading, setLoading] = useState(false);

  const { masterCatlist, masterCardList, activeTab } = state;

  // Fetch categories when the component comes into view
  useEffect(() => {
    fetchMasterCatList();
  }, []);

  const fetchMasterCatList = async () => {
    try {
      let params = { all: true };
      const response = await getMasterCategoryList(params);
      if (response?.status === "Success") {
        setState((prev) => ({ ...prev, masterCatlist: response?.data }));
      }
    } catch (error) {
      console.log("Error fetching categories:", error);
    }
  };

  // Fetch subcategories when the active category changes
  useEffect(() => {
    if (Array.isArray(masterCatlist) && masterCatlist?.length > 0) {
      fetchSubCat(masterCatlist?.[0]?._id);
      setState((prev) => ({ ...prev, activeTab: masterCatlist?.[0]?._id }));
    }
  }, [masterCatlist]);

  const fetchSubCat = async (catId) => {
    setLoading(true);
    setState((prev) => ({
      ...prev,
      activeTab: catId,
    }));
    try {
      const response = await getMasterCardList(catId);
      if (response?.status === "Success") {
        setState((prev) => ({
          ...prev,
          masterCardList: response?.data,
          activeTab: catId,
        }));
      }
      setLoading(false);
    } catch (error) {
      console.log("Error fetching subcategories:", error);
      setLoading(false);
    } finally {
    }
  };

  return (
    <div className="bg-primary">
      <div className="container mx-auto px-4 py-3 lg:py-8">
        <div className="flex flex-wrap justify-between gap-11">
          <div className="w-full md:w-2/4">
            <h6 className="mb-2 text-base font-medium text-color-danger md:mb-1">
              Enquiry about Brands
            </h6>
            <h2 className="mb-8 text-lg md:text-2xl md:font-semibold">
              Select Your Category
            </h2>
          </div>
        </div>
        <div className="scrollbar-none mb-10 flex flex-wrap items-center gap-4 overflow-x-auto md:flex-wrap md:gap-2">
          {masterCatlist?.map((cat, i) => (
            <div
              key={i}
              className={`cursor-pointer whitespace-nowrap rounded-xl border border-solid border-primary-border-color bg-secondary p-2 px-3 py-2 text-xs font-medium text-color-black hover:bg-primary-color hover:text-white md:text-xs ${
                cat?._id === activeTab ? "activeCat" : ""
              }`}
              onClick={() => fetchSubCat(cat?._id)}
            >
              {cat?.name}
            </div>
          ))}
        </div>
        {loading ? (
          <SkeletonCard
            desktopCount={10}
            mobileCount={3}
            tabletCount={3}
            showCardTitle={false}
          />
        ) : (
          <div className="scrollbar-none mx-[-10px] flex flex-nowrap gap-[20px] overflow-x-auto sm:grid-cols-4 md:grid lg:mx-0 lg:grid-cols-3 lg:gap-[30px] xl:grid-cols-5 xl:gap-[36px]">
            {masterCardList?.slice(0, 10)?.map((el, index) => (
              <div
                className="min-w-[10rem] max-w-[10rem] sm:max-w-[18rem] lg:min-w-[15rem] lg:max-w-[100%]"
                key={index}
              >
                <div className="relative mx-[10px] rounded-s border border-gray-200 bg-white shadow-xs md:w-auto lg:mx-0">
                  <div className="absolute left-2 top-2 z-20">
                    <span className="rounded bg-tertiary px-2 py-1 text-xxs font-medium text-color-grey">
                      {el?.categoryName}
                    </span>
                  </div>

                  <Link
                    href={{
                      pathname: `${createSlug(`${el?.name}`)}`,
                      query: {
                        fsl: activeTab,
                        fml: el?.cat_id,
                        fnsl: el?._id,
                        page: 1,
                        limit: 20,
                        type: "subcat",
                      },
                    }}
                    className="relative block"
                    prefetch={true}
                  >
                    <ImageThumbnail
                      src={el?.image || HomepageCategoryImage}
                      defaultImg={HomepageCategoryImage}
                      alt="image"
                      paddingBottom="55%"
                      height={100}
                      width={100}
                    />
                  </Link>
                  <div className="pt-3">
                    <div className="px-4 pb-3">
                      <h6 className="mb-0 line-clamp-1 text-xs font-semibold tracking-tight text-color-grey">
                        {el?.name}
                      </h6>
                      <p className="line-clamp-2 text-ellipsis text-xxs font-medium text-color-grey">
                        {el?.meta_description}
                      </p>
                    </div>
                    <Link
                      href={{
                        pathname: `${createSlug(`${el?.name}`)}`,
                        query: {
                          fsl: activeTab,
                          fml: el?.cat_id,
                          fnsl: el?._id,
                          page: 1,
                          limit: 20,
                          type: "subcat",
                        },
                      }}
                      className="inline-flex h-[40px] w-full items-center justify-center rounded-bl rounded-br bg-tertiary px-3 py-2 text-sm font-medium text-color-black hover:bg-primary-color hover:text-white"
                    >
                      View more
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="mt-2 lg:mt-10">
          <Link
            href="/franchisecategory"
            className="inline-flex items-center justify-center text-sm font-medium text-primary hover:underline"
          >
            <span className="w-full">See all Options</span>
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.48593 4.31527L11.1666 8.96786L6.51404 13.6486"
                stroke="#1E7FD9"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomepageCategory;
