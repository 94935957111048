"use client";

import { apiClient as blogClient } from "@/config/blogClient";
import { API_ENDPOINTS } from "@/constants/endpoints";

const { BLOG } = API_ENDPOINTS;

export const getLatestBlogList = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = BLOG.getLatestBlogList();
      const res = await blogClient({ url: url, method, params: params });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getParentCategoryList = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = BLOG.getParentCategoryList();
      const res = await blogClient({ url: url, method, params: params });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getBlogCategoryList = (catId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = BLOG.getBlogCategoryList(catId);
      const res = await blogClient({ url: url, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getBlogList = (params) => {
  return new Promise(async (resolve, reject) => {
    //console.log(params, '-----------');
    try {
      const { url, method } = BLOG.getBlogList();
      //console.log(url, method, params);
      const res = await blogClient({ url: url, method, params: params });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getBlogDetail = (params) => {
  return new Promise(async (resolve, reject) => {
    //console.log(params, '-----------');
    try {
      const { url, method } = BLOG.getBlogDetail();
      //console.log(url, method, params);
      const res = await blogClient({ url: url, method, params: params });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

//Wishlist

export const getWishlistList = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = WISHLIST.getWishlistList();
      const res = await brandClient({
        url: url,
        method,
        params: params,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateWishlist = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = WISHLIST.updateWishlist();
      const res = await brandClient({
        url: url,
        method,
        data: {},
        params: params,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

//Contact Brand
export const getContactBrandList = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CONATACT_BRAND.getContactBrandList();
      const res = await brandClient({
        url: url,
        method,
        params: params,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const directContactBrand = (catalogue_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CONATACT_BRAND.directContactBrand(catalogue_id);
      const res = await brandClient({ url: url, method, data: {} });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getContactDetails = (catalogue_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CONATACT_BRAND.getContactDetails(catalogue_id);
      const res = await brandClient({
        url: url,
        method,
      });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCatalogueTopIndianList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOGUE.getCatalogueTopIndianList();
      const res = await brandClient({ url: url, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCatalogueTopMaxInvestedList = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOGUE.getCatalogueTopMaxInvestedList();
      const res = await brandClient({ url: url, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

//Compate catalogue
export const postCatalogueCompare = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATALOGUE.postCatalogueCompare();
      const res = await brandClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
