"use client";
import { createSlice } from "@reduxjs/toolkit";
import { getProfileData } from "../thunks/profileThunks";
import { getAuthData, setAuthData, removeAuthData } from "@/utils/authHelper";
import { isObject } from "@/utils/helper";
// Initial state for the profile slice
const initialUser = getAuthData() || null;
const initialIsAuthenticated = !!initialUser;
const initialState = {
  profile: null,
  loading: false,
  error: null,
  auth: initialUser,
  isAuthenticated: initialIsAuthenticated,
};

// Create the profile slice
const profileSlice = createSlice({
  name: "authState",
  initialState,
  reducers: {
    setUser: (state, action) => {
      //console.log("action.payloadaction.payloadaction.payload", action.payload);
      state.auth = action.payload;
      state.isAuthenticated =
        action.payload && isObject(action.payload) ? true : false;
      setAuthData(action.payload);
    },
    clearUser: (state) => {
      state.auth = null;
      state.isAuthenticated = false;
      state.profile = null;
      removeAuthData();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProfileData.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(getProfileData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const profileReducer = profileSlice.reducer;
export const { setUser, clearUser } = profileSlice.actions;
