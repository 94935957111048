// "use client";

// import React, { useState } from "react";
// import { useInView } from "react-intersection-observer";

// const LazyLoadSection = ({ children, threshold = 0.1 }) => {
//   const [isLoaded, setIsLoaded] = useState(false);
//   const { ref, inView } = useInView({
//     threshold: threshold,
//     triggerOnce: true,
//     onChange: (inView) => {
//       if (inView) setIsLoaded(true);
//     },
//   });

//   return (
//     <div ref={ref}>
//       {isLoaded ? children : <div style={{ minHeight: "200px" }} />}{" "}
//     </div>
//   );
// };

// export default LazyLoadSection;

// LazyLoadSection.js

// LazyLoadSection.js

"use client";

import useResponsive from "@/hooks/useResponsive";
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

const LazyLoadSection = ({
  children,
  threshold = 0.1,
  rootMargin, // Load 200px before the section comes into view
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { isMobile } = useResponsive();
  let defaultRootMargin = isMobile
    ? "150px 0px 150px 0px"
    : "300px 0px 300px 0px";
  const { ref, inView } = useInView({
    threshold: threshold,
    rootMargin: rootMargin || defaultRootMargin, // Adjust this margin to load sections earlier
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) setIsLoaded(true);
    },
  });

  return (
    <div ref={ref}>
      {isLoaded ? children : <div style={{ minHeight: "200px" }} />}{" "}
      {/* Placeholder */}
    </div>
  );
};

export default LazyLoadSection;
