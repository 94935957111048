// authModalSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showModal: false,
  pageName: null,
  showPyrModal: false,
};

const authModalSlice = createSlice({
  name: "authModal",
  initialState,
  reducers: {
    openModal(state, action) {
      state.showModal = true;
      state.pageName = action.payload;
    },
    closeModal(state) {
      state.showModal = false;
      state.pageName = null;
    },

    openPyrModal(state) {
      state.showPyrModal = true;
    },
    closePyrModal(state) {
      state.showPyrModal = false;
    },
  },
});

export const { openModal, closeModal, openPyrModal, closePyrModal } =
  authModalSlice.actions;

export default authModalSlice.reducer;
