export const API_ENDPOINTS = {
  AUTH: {
    register: () => ({
      url: "/api/v1/investor/user",
      method: "POST",
    }),
    loginWithPassword: () => ({
      url: "/api/v1/investor/login/password",
      method: "POST",
    }),
    verifyOtp: () => ({
      url: "/api/v1/investor/login/otp",
      method: "POST",
    }),
    resendOtp: () => ({
      url: "/api/v1/investor/send/otp",
      method: "POST",
    }),
    getProfile: () => ({
      url: "/api/v1/investor/profile",
      method: "GET",
    }),
    updateProfile: () => ({
      url: "/api/v1/investor/profile",
      method: "PUT",
    }),
    saveBillingDetails: () => ({
      url: "/api/v1/billing/details",
      method: "POST",
    }),
    refreshToken: () => ({
      url: "/api/v1/login/refresh",
      method: "POST",
    }),
  },

  PROFILE: {
    sendOtpCode: () => ({
      url: "/api/v1/investor/send/otpcode",
      method: "POST",
    }),
    verifyOtpCode: () => ({
      url: "/api/v1/investor/verify/otp",
      method: "POST",
    }),
    sendOtpCodeForUpdate: () => ({
      url: "/api/v1/investor/send/code",
      method: "POST",
    }),
  },
  PYR: {
    getCatalogKeywords: () => ({
      //url: "/api/v1/brandservice/catalogues/keyword",
      url: "/api/v1/keywords/suggestion",
      method: "GET",
    }),

    findInvestor: () => ({
      url: "/api/v1/investor/find",
      method: "GET",
    }),
    savePyrForm: () => ({
      url: "/api/v1/pyr/save",
      method: "POST",
    }),
    updatePyrForm: (pyrid) => ({
      url: `/api/v1/pyr/save/${pyrid}`,
      method: "PUT",
    }),
    verifyPyrForm: (pyrid) => ({
      url: `/api/v1/pyr/verify/${pyrid}`,
      method: "POST",
    }),
    sendOtp: () => ({
      url: `/api/v1/investor/send/otp`,
      method: "POST",
    }),
    directSavePyrFormToBrand: () => ({
      url: "/api/v1/pyr/brand/save",
      method: "POST",
    }),
    addAppointment: () => ({
      url: "/api/v1/investor/book/verification-call",
      method: "POST",
    }),
    addAdditionalInfo: (id) => ({
      url: `/api/v1/pyr/info/${id}`,
      method: "PUT",
    }),
  },
  COUNTRY: {
    getCountries: () => ({
      url: `/api/v1/countries`,
      method: "GET",
    }),
    getStates: () => ({
      url: `/api/v1/states`,
      method: "GET",
    }),
    getCities: () => ({
      url: `/api/v1/cities`,
      method: "GET",
    }),
  },

  MISC: {
    getHeaderData: () => ({
      url: `/api/v1/web/categories?all=false`,
      method: "GET",
    }),

    getAllCategory: () => ({
      url: `/api/v1/web/categories?all=true`,
      method: "GET",
    }),

    getDropdownData: () => ({
      url: "/api/v1/misc",
      method: "GET",
    }),
    getIndustry: () => ({
      url: `/api/v1/master_industry`,
      method: "GET",
    }),

    getConnectedBrands: () => ({
      url: `/api/v1/misc/connected/brands`,
      method: "GET",
    }),
  },

  CATALOGUE: {
    getCatalogueList: () => ({
      url: `/api/v1/catalogues/filter`,
      method: "GET",
    }),
    getCatalogueFilters: () => ({
      url: `/api/v1/misc/web/catalogue/filter`,
      method: "GET",
    }),
    getCatalogueDetails: () => ({
      url: `/api/v1/brandservice/getbrandcataloguedetail`,
      method: "GET",
    }),
    getCatalogueSuggestion: () => ({
      url: `/api/v1/catalogues/suggestion`,
      method: "GET",
    }),
    searchCatalogue: () => ({
      url: `/api/v1/catalogues/search`,
      method: "GET",
    }),
    getCatalogueTopIndianList: () => ({
      url: `/api/v1/brandservice/catalogue/list/top`,
      method: "GET",
    }),
    getCatalogueTopMaxInvestedList: () => ({
      url: `/api/v1/brandservice/catalogue/list/invested`,
      method: "GET",
    }),
    postCatalogueCompare: () => ({
      url: `/api/v1/brandservice/catalogue_compare`,
      method: "POST",
    }),
  },
  MY_ORDERS: {
    getInvestorOrders: () => ({
      url: `/api/v1/pyr/investor/orders`,
      method: "GET",
    }),
    getConsumeBrands: (id) => ({
      url: `/api/v1/pyr/consume/brands/${id}`,
      method: "GET",
    }),
    closeOrders: (id) => ({
      url: `/api/v1/pyr/investor/order/${id}`,
      method: "DELETE",
    }),
  },

  WISHLIST: {
    getWishlistList: () => ({
      url: `/api/v1/brandservice/wishlist`,
      method: "GET",
    }),
    updateWishlist: () => ({
      url: `/api/v1/brandservice/wishlist`,
      method: "POST",
    }),
  },
  CONATACT_BRAND: {
    getContactBrandList: () => ({
      url: `/api/v1/brandservice/brand/contact/catalogues`,
      method: "GET",
    }),
    directContactBrand: (catalogue_id) => ({
      url: `/api/v1/brandservice/brand/contact/${catalogue_id}`,
      method: "POST",
    }),
    getContactDetails: (catalogue_id) => ({
      url: `/api/v1/brandservice/brand/contact/details/${catalogue_id}`,
      method: "GET",
    }),
  },
  FAQ: {
    getFAQList: (catalogue_id) => ({
      url: `/api/v1/faq/list/${catalogue_id}`,
      method: "GET",
    }),
  },
  CATEGORY_LIST: {
    // getMasterCategoryList: () => ({
    //   url: `/api/v1/master/category/data/listall`,
    //   method: "GET",
    // }),

    getMasterCategoryList: () => ({
      url: `/api/v1/web/categories`,
      method: "GET",
    }),
    getMasterCardList: (catId) => ({
      url: `/api/v1/master/category/catlist/${catId}`,
      method: "GET",
    }),
    getPublicBussinessCategoryData: () => ({
      url: "/api/v1/master/category/data/listall",
      method: "GET",
    }),
    getCategoryData: (sup_cat_id) => ({
      url: `/api/v1/category/list/${sup_cat_id}`,
      method: "GET",
    }),
    getSubCategoryData: (cat_id) => ({
      url: `/api/v1/subCategory/list/${cat_id}`,
      method: "GET",
    }),
    getMultiCategoryData: () => ({
      url: `/api/v1/category/multiple/list`,
      method: "POST",
    }),
    getMultiSubCategoryData: () => ({
      url: `/api/v1/subCategory/multiple/list`,
      method: "POST",
    }),
  },
  META_DATA_DETAILS: {
    getListingPageSeoMetaData: () => ({
      url: `/api/v1/master/category/cat/seo`,
      method: "GET",
    }),
  },

  PLAN: {
    getPlans: () => ({
      url: "/api/v1/investor/plans",
      method: "GET",
    }),
    createOrder: () => ({
      url: "/api/v1/investor/order",
      method: "POST",
    }),
    verifySignature: () => ({
      url: "/api/v1/investor/subscription/verifyPayment",
      method: "POST",
    }),
    getSubscriptions: () => ({
      url: "/api/v1/subscriptions",
      method: "GET",
    }),
    getActiveSubscription: () => ({
      url: "/api/v1/active/subscription",
      method: "GET",
    }),
    getHistoryDetailsSubscription: () => ({
      url: "/api/v1/subscriptions",
      method: "GET",
    }),
    downloadPaymentData: () => ({
      url: "/api/v1/invoice/download",
      method: "GET",
    }),
    cancelSubscription: () => ({
      url: "/api/v1/subscription/cancel",
      method: "PUT",
    }),
  },

  BLOG: {
    getLatestBlogList: () => ({
      url: `/api/v1/content/latest`,
      method: "GET",
    }),

    getParentCategoryList: () => ({
      url: `/api/v1/content/category/pcatlist`,
      method: "GET",
    }),

    getBlogCategoryList: (catId) => ({
      url: `/api/v1/content/category/catlist/${catId}`,
      method: "GET",
    }),

    getBlogList: () => ({
      url: `/api/v1/content/list`,
      method: "GET",
    }),

    getBlogDetail: () => ({
      url: `/api/v1/content/detail`,
      method: "GET",
    }),
  },
  PAGE: {
    saveContactus: () => ({
      url: "/api/v1/contactus/create",
      method: "POST",
    }),
    saveFeedback: () => ({
      url: "/api/v1/feedback/create",
      method: "POST",
    }),

    saveNewsletter: () => ({
      url: "/api/v1/newsletter/create",
      method: "POST",
    }),
  },
};
