import axios from "axios";
import {
  AUTH_TOKEN_KEY,
  AUTH_REFRESH_TOKEN_KEY,
  AUTH_KEY,
} from "../constants/constant";
import { getParsedItem, removeItem, setParsedItem } from "../utils/Storage";
import { refreshToken } from "@/services/AuthServices";
//import { store } from "@/store/store";
// import { clearUser } from "@/store/slices/authSlice";
//import { removeAllCompare } from "@/store/slices/compareSlice";
//import { openModal } from "@/store/slices/authModalSlice";

let isRefreshing = false;
let refreshTokenPromise = null;
const restrictUrlsForRefreshToken = ["/api/v1/login/refresh"];

//Export the function to create the axios instance
export const createAxiosInstance = (baseURL) => {
  const refresh_token = getParsedItem(AUTH_REFRESH_TOKEN_KEY);
  //const { dispatch } = store;

  const instance = axios.create({
    baseURL: baseURL,
  });

  instance.interceptors.request.use(
    async (config) => {
      const token = getParsedItem(AUTH_TOKEN_KEY);
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        refresh_token &&
        !restrictUrlsForRefreshToken.includes(originalRequest.url)
      ) {
        originalRequest._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;
          refreshTokenPromise = refreshTokenHandler();
        }

        return enqueueRequest(originalRequest);
      }

      return Promise.reject(error);
    }
  );

  return instance;
};
// Function to handle token refresh
const refreshTokenHandler = async () => {
  try {
    const token = getParsedItem(AUTH_REFRESH_TOKEN_KEY);
    const response = await refreshToken({ token });
    const accessToken = response?.data?.access_token;
    setParsedItem(AUTH_TOKEN_KEY, accessToken);
    return accessToken;
  } catch (error) {
    console.error("Token refresh failed:", error);
    // Handle token refresh failure
    removeItem(AUTH_TOKEN_KEY);
    removeItem(AUTH_REFRESH_TOKEN_KEY);
    removeItem(AUTH_KEY);
    //window.location.href = "/";
    window.location.reload();
    // dispatch(clearUser());
    // dispatch(removeAllCompare());
    // dispatch(openModal("sign-in"));

    return Promise.reject(error);
  } finally {
    isRefreshing = false; // Reset flag after token refresh attempt
  }
};

// Function to enqueue failed requests
const enqueueRequest = (request) => {
  return new Promise((resolve, reject) => {
    refreshTokenPromise
      .then((accessToken) => {
        // Retry the original request with the new token
        request.headers["Authorization"] = `Bearer ${accessToken}`;
        resolve(axios(request));
      })
      .catch((error) => {
        reject(error);
      });
  });
};
