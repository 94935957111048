"use client";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { getImageUrl } from "@/utils/helper";
import "./ImageThumbnail.scss";
import PlaceholderImage from "@/assets/images/placeholder.jpeg";

const ImageThumbnail = ({
  src,
  unoptimized = true,
  defaultImg = PlaceholderImage,
  className = "",
  style = {},
  imgStyle = {},
  imgClassName = "",
  paddingBottom = "100%",
  sizes = "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw",

  ...props
}) => {
  const [imgSrc, setImgSrc] = useState(
    typeof src === "string" && src.startsWith("https")
      ? src
      : src
      ? getImageUrl(src)
      : defaultImg
  );

  const handleError = () => {
    setImgSrc(defaultImg);
  };

  useEffect(() => {
    setImgSrc(
      typeof src === "string" && src.startsWith("https")
        ? src
        : src
        ? getImageUrl(src)
        : defaultImg
    );
  }, [src]);

  return (
    <div
      className={`custom_thumbnail ${className}`}
      style={{ ...style, "--padding-bottom": paddingBottom }}
    >
      <Image
        src={imgSrc}
        //src="https://m.media-amazon.com/images/I/71udcnhlrvL._AC_UL640_FMwebp_QL65_.jpg"
        // src={
        //   "https://m.media-amazon.com/images/I/71pbmq2jLjL._AC_UL640_FMwebp_QL65_.jpg"
        // }
        //src="https://cdn.shopify.com/s/files/1/0752/6435/files/IMG_0036_13_800x.jpg?v=1687424711"
        //src={"https://cdn.shopify.com/s/files/1/0805/5128/0930/files/329070381_01_Style_Shot_ff3ea88c-f7b3-4ce5-ad45-d66779cb2a75.jpg?v=1696411695&width=750"}
        //src="https://rukminim2.flixcart.com/image/612/612/xif0q/shoe/o/v/e/6-rng-512-black-40-bruton-black-original-imagexnpgzqzzbze-bb.jpeg?q=70"
        unoptimized={unoptimized}
        onError={handleError}
        className={imgClassName}
        style={imgStyle}
        // sizes={sizes}
        {...props}
      />
    </div>
  );
};

export default ImageThumbnail;

//Most fit dimention for website
//https://m.media-amazon.com/images/I/71udcnhlrvL._AC_UL640_FMwebp_QL65_.jpg
//https://m.media-amazon.com/images/I/71pbmq2jLjL._AC_UL640_FMwebp_QL65_.jpg

{
  /* <ImageThumbnail
  src="https://example.com/image.jpg"
  defaultImg="/default.jpg"
  className="my-thumbnail"
  style={{ borderColor: 'blue' }}
  imgClassName="my-image"
  imgStyle={{ borderRadius: '50%' }}
  paddingBottom="75%"
  alt="Custom Image"
/> */
}
