"use client";
import React, { useEffect, useState } from "react";
import HomepageCarousel from "@/components/Homepage/HomepageCarousel";
import ClientOnly from "@/components/common/ClientOnly";
import { getLatestBlogList } from "@/services/blogServices";
import { createBlogDetailsPageSlug } from "../../../utils/helper";
import Link from "next/link";
import ImageThumbnail from "@/components/common/ImageThumbnail/ImageThumbnail";
import SkeletonCard from "@/components/common/Skeleton/SkeletonCard/SkeletonCard";

const FeaturedBlogs = () => {
  const [featureBlogs, setFeatureBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchFeatureBlogList = async () => {
    setLoading(true);
    try {
      const response = await getLatestBlogList();
      if (response?.status === "Success") {
        setFeatureBlogs(response?.data);
      }
    } catch (error) {
      console.log("eeeeeeeeee", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFeatureBlogList();
  }, []);

  return (
    <div className="bg-primary">
      <div className="container mx-auto py-3 lg:py-8 ">
        {loading ? (
          <SkeletonCard />
        ) : (
          <>
            <ClientOnly>
              <HomepageCarousel
                title="Featured Blogs"
                subtitle=""
                dots={true}
                slidesToShow={5}
              >
                {featureBlogs.map((el, index) => (
                  <div
                    className=" relative mx-[18px] mb-4 h-full  rounded-s border border-gray-200  bg-white shadow-xs"
                    key={index}
                  >
                    <Link
                      href={`${createBlogDetailsPageSlug(el)}`}
                      className="relative"
                    >
                      <div className=" relative">
                        <ImageThumbnail
                          src={el?.image}
                          alt="image"
                          width={100}
                          height={127}
                          paddingBottom="60%"
                          imgClassName="mt-0"
                        />
                      </div>
                    </Link>
                    <div className="pt-3">
                      <div className="px-3 pb-3">
                        <Link
                          href={`${createBlogDetailsPageSlug(el)}`}
                          className="relative"
                        >
                          <h5 className="line-clamp-2 text-ellipsis text-sm font-semibold tracking-tight text-color-grey">
                            {el.title}
                          </h5>
                        </Link>
                      </div>

                      <div className="px-3 pb-3 lg:mt-2">
                        <Link
                          href={`${createBlogDetailsPageSlug(el)}`}
                          className=" inline-flex items-center justify-center text-sm font-medium text-primary hover:underline "
                        >
                          <span className="w-full">Read more</span>
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.48593 4.31527L11.1666 8.96786L6.51404 13.6486"
                              stroke="#1E7FD9"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </HomepageCarousel>
            </ClientOnly>
            <div className="relative z-20 mt-0 inline-block">
              <Link
                href="/content/blogs"
                className=" inline-flex items-center justify-center text-sm font-medium text-primary hover:underline "
              >
                <span className="w-full">View More</span>
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.48593 4.31527L11.1666 8.96786L6.51404 13.6486"
                    stroke="#1E7FD9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FeaturedBlogs;
