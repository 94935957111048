// authInvestoreData.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    investorData: null,
};

const authInvestoreData = createSlice({
    name: "investorDetails",
    initialState,
    reducers: {
        setInvestorData(state, action) {
            state.investorData = action.payload;
        },
        removeInvestore(state, action) {
            state.investorData = null
        },
    },
});

export const { setInvestorData, removeInvestore } = authInvestoreData.actions;

export default authInvestoreData.reducer;
