"use client";
import React from "react";
import ClientOnly from "@/components/common/ClientOnly";
import HomepageCarousel from "@/components/Homepage/HomepageCarousel";
import { YouTubeEmbed } from "@next/third-parties/google";

const ExploreVideos = () => {
  const blogs = [
    {
      title:
        "What are the Franchise Models? | FOFO | FOCO | FICO | COCO | COFO",
      url: "https://www.youtube.com/embed/_WENEw8Laao?si=CeIWoB1h4AZOPdfQ",

      embedId: "_WENEw8Laao",
    },
    {
      title:
        "List of Different Franchise Laws in India | Franchise Agreement | Franchise Business",
      url: "https://www.youtube.com/embed/mrkKpB2qEsk?si=8yzzaQP6XUZoua49",
      embedId: "mrkKpB2qEsk",
    },
    {
      title:
        "What are the benefit of taking Franchise instead of starting your own Brand",
      url: "https://www.youtube.com/embed/C_TYtdFFkgM?si=shF137MWO0ptln-2",
      embedId: "C_TYtdFFkgM",
    },
    {
      title: "What is Master Franchise | How to take Master Franchise",
      url: "https://www.youtube.com/embed/elO87LWgJxs?si=Xas62741InFv2muZ",
      embedId: "elO87LWgJxs",
    },
    {
      title: "5 Franchise Term you need to know before buying a franchise",
      url: "https://www.youtube.com/embed/J7Z0_zfAy0c?si=QxFejpCiztbG_Civ",
      embedId: "J7Z0_zfAy0c",
    },
    {
      url: "https://www.youtube.com/embed/NTEFh2sNJlw?si=AhNc7jBaj34oM8ez",
      title:
        "How to setup Master Franchise -Eligibility, Expansion Strategy, & Business Responsibilities",
      embedId: "NTEFh2sNJlw",
    },
    {
      url: "https://www.youtube.com/embed/6ciAPtn7nXU?si=eMAK1qQnqd16a7a3",
      title:
        "How to Franchise & Distribute Your Brand : Right Timing, Documentation, & Strategies",
      embedId: "6ciAPtn7nXU",
    },
    {
      url: "https://www.youtube.com/embed/paasg5F4YVc?si=DGsD2Mnn4_2tzYUX",
      title:
        "Reason why 90% Startup Fails within 5 year | How to reach Venture Capitalist",
      embedId: "paasg5F4YVc",
    },
    {
      url: "https://www.youtube.com/embed/UT2tFsLK5jU?si=RQxcEJbgFmx9zHlS",
      title: "What is the meaning of Area Developer in Franchise Business?",
      embedId: "UT2tFsLK5jU",
    },
    {
      url: "https://www.youtube.com/embed/BnRAkwukuVM?si=UrzwCaRV3K9vKK5H",
      title:
        "How does the bakery industry work? | What is Standalone Model and Live Kitchen Model?",
      embedId: "BnRAkwukuVM",
    },
  ];

  return (
    <div
      className="container mx-auto py-8"
      style={{ backgroundColor: "#F3F3F3" }}
    >
      <ClientOnly>
        <HomepageCarousel
          title="Explore Videos"
          subtitle=""
          dots={true}
          slidesToShow={5}
        >
          {blogs.map((el, index) => (
            <div
              className="relative mx-[18px] mb-4 rounded border border-gray-200 bg-white shadow-xs"
              key={index}
            >
              <div className="relative cursor-pointer">
                <YouTubeEmbed videoid={el.embedId} height={150} />
              </div>
              <div className="mt-4 px-3 pb-2">
                <h6 className="mb-2 line-clamp-2 text-ellipsis text-sm font-semibold tracking-tight text-color-grey">
                  {el.title}
                </h6>
              </div>
            </div>
          ))}
        </HomepageCarousel>
      </ClientOnly>
    </div>
  );
};

export default ExploreVideos;
