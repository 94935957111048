import {
  AUTH_KEY,
  AUTH_TOKEN_KEY,
  AUTH_REFRESH_TOKEN_KEY,
  AUTH_AUTHENTICATED,
  REDUX_STORE_KEY,
} from "@/constants/constant";
import { setParsedItem, getParsedItem, removeItem } from "./Storage";

const hasStoregae = typeof localStorage !== (undefined || "undefined");
const setAuthData = (authData) => {
  //console.log("setAuthDatasetAuthData", authData);
  if (authData) {
    setParsedItem(AUTH_KEY, authData);
    setParsedItem(AUTH_TOKEN_KEY, authData?.access_token);
    setParsedItem(AUTH_REFRESH_TOKEN_KEY, authData?.refresh_token);
    setParsedItem(AUTH_AUTHENTICATED, true);
  }
};

const getAuthData = () => {
  return getParsedItem(AUTH_KEY);
};

const getAuthIsAuthenticated = () => {
  return getParsedItem(AUTH_AUTHENTICATED)
    ? getParsedItem(AUTH_AUTHENTICATED)
    : false;
};

const removeAuthData = () => {
  removeItem(AUTH_KEY);
  removeItem(AUTH_TOKEN_KEY);
  removeItem(AUTH_REFRESH_TOKEN_KEY);
  removeItem(REDUX_STORE_KEY);
  setParsedItem(AUTH_AUTHENTICATED, false);
};

export { setAuthData, getAuthData, removeAuthData, getAuthIsAuthenticated };

// Define the current state version for versioning
const CURRENT_VERSION = 1;

export const loadState = () => {
  try {
    if (hasStoregae) {
      const serializedState = localStorage.getItem("reduxState");
      if (serializedState === null) {
        return undefined;
      }
      const state = JSON.parse(serializedState);

      // Ensure the stored state version matches the current version
      const { version, ...restoredState } = state;
      if (version !== CURRENT_VERSION) {
        localStorage.removeItem("reduxState");
        return undefined;
      }

      return restoredState;
    }
    return undefined;
  } catch (err) {
    console.error("Could not load state", err);
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const stateToSave = {
      version: CURRENT_VERSION,
      ...state,
    };
    const serializedState = JSON.stringify(stateToSave);
    localStorage.setItem("reduxState", serializedState);
  } catch (err) {
    console.error("Could not save state", err);
  }
};
