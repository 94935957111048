"use client";

// hooks/useAuth.js
import { useDispatch, useSelector } from "react-redux";
//import { clearUser, setUser } from "@/store/slices/authSlice";
import { useRouter } from "next/navigation";
import { removeAllCompare } from "@/store/slices/compareSlice";
import { removeInvestore } from "@/store/slices/authInvestoreData";
import { setUser, clearUser } from "@/store/slices/profileSlice";

const useAuth = () => {
  const router = useRouter();

  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authState?.auth?.user);
  const auth = useSelector((state) => state?.authState?.auth);

  const profile = useSelector((state) => state?.authState?.profile);
  const access_token = useSelector(
    (state) => state?.authState?.auth?.access_token
  );
  const isAuthenticated = useSelector(
    (state) => state?.authState?.isAuthenticated
  );
  //console.log("useAuthuseAuthuseAuth", user);

  const authLogin = (userData) => {
    //console.log("userDatauserData", userData);
    dispatch(setUser(userData));
  };

  const authLogout = () => {
    dispatch(clearUser());
    dispatch(removeAllCompare());
    dispatch(removeInvestore());

    router.push("/");
  };

  // console.log({ user, isAuthenticated });

  return {
    user,
    profile,
    access_token,
    isAuthenticated,
    authLogin,
    authLogout,
    auth,
  };
};

export default useAuth;
