const hasStorage = typeof localStorage !== "undefined";

const getItem = (key) => {
  try {
    return hasStorage ? localStorage.getItem(key) : null;
  } catch (error) {
    console.error(
      `Error getting item from localStorage for key '${key}':`,
      error
    );
    return null;
  }
};

const setItem = (key, value) => {
  try {
    if (hasStorage) localStorage.setItem(key, value);
  } catch (error) {
    console.error(
      `Error setting item in localStorage for key '${key}':`,
      error
    );
  }
};

const getParsedItem = (key) => {
  try {
    const item = getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error(
      `Error parsing item from localStorage for key '${key}':`,
      error
    );
    return null;
  }
};

const setParsedItem = (key, value) => {
  try {
    setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(
      `Error setting parsed item in localStorage for key '${key}':`,
      error
    );
  }
};

const removeItem = (key) => {
  try {
    if (hasStorage) localStorage.removeItem(key);
  } catch (error) {
    console.error(
      `Error removing item from localStorage for key '${key}':`,
      error
    );
  }
};

//session storage
const sessionHandler = {
  set: (key, value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting session storage for key '${key}':`, error);
    }
  },
  get: (key) => {
    try {
      const storedItem = sessionStorage.getItem(key);
      return storedItem ? JSON.parse(storedItem) : null;
    } catch (error) {
      console.error(`Error getting session storage for key '${key}':`, error);
      return null;
    }
  },
  remove: (key) => {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error(
        `Error removing session storage item for key '${key}':`,
        error
      );
    }
  },
};

export {
  getItem,
  setItem,
  getParsedItem,
  setParsedItem,
  removeItem,
  sessionHandler,
};
