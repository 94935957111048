"use client";

import { apiClient as masterClient } from "@/config/masterClient";
import { API_ENDPOINTS } from "@/constants/endpoints";

const { CATEGORY_LIST } = API_ENDPOINTS;

export const getMasterCategoryList = (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATEGORY_LIST.getMasterCategoryList();
      const res = await masterClient({ url: url, method, params });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getMasterCardList = (catId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = CATEGORY_LIST.getMasterCardList(catId);
      const res = await masterClient({ url: url, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getPublicBussinessCategoryData = (catId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } =
        CATEGORY_LIST.getPublicBussinessCategoryData(catId);
      const res = await masterClient({ url: url, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getCategoryData = async (sup_cat_id) => {
  try {
    const { url, method } = CATEGORY_LIST.getCategoryData(sup_cat_id);
    const res = await masterClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};

export const getSubCategoryData = async (cat_id) => {
  try {
    const { url, method } = CATEGORY_LIST.getSubCategoryData(cat_id);
    const res = await masterClient({ url, method });
    return res;
  } catch (error) {
    return error;
  }
};

export const getMultiCategoryData = async (sup_cat_ids) => {
  try {
    const { url, method } = CATEGORY_LIST.getMultiCategoryData();
    const res = await masterClient({
      url, method, data: {
        "sup_cat_ids": sup_cat_ids
      }
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getMultiSubCategoryData = async (cat_ids) => {
  try {
    const { url, method } = CATEGORY_LIST.getMultiSubCategoryData();
    const res = await masterClient({
      url, method, data: {
        "cat_ids": cat_ids
      }
    });
    return res;
  } catch (error) {
    return error;
  }
};