import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  compare_catalogues: [],
};

const compareSlice = createSlice({
  name: "compare",
  initialState,
  reducers: {
    addToCompare(state, action) {
      const catalogue = action.payload;
      if (
        !state.compare_catalogues.some(
          (item) => item.catalogue_id === catalogue.catalogue_id
        )
      ) {
        state.compare_catalogues.push(catalogue);
      }
    },
    removeFromCompare(state, action) {
      const catalogue = action.payload;
      state.compare_catalogues = state.compare_catalogues.filter(
        (item) => item.catalogue_id !== catalogue.catalogue_id
      );
    },
    removeAllCompare(state) {
      state.compare_catalogues = [];
    },
  },
});

export const { addToCompare, removeFromCompare, removeAllCompare } =
  compareSlice.actions;

export default compareSlice.reducer;
