export const AUTH_KEY = "fd_auth";
export const AUTH_TOKEN_KEY = "fd_token";
export const AUTH_REFRESH_TOKEN_KEY = "fd_refresh_token";
export const AUTH_AUTHENTICATED = "isAuthenticated";
export const REDUX_STORE_KEY = "reduxState";

export const NORMAL_NUMBERS_FILTER_IDS = [
  "establishment_year_range",
  "outlates_range",
];

export const footerCitiesArray = [
  { label: "Mumbai", key: "Mumbai" },
  { label: "Delhi", key: "Delhi" },
  { label: "Bengaluru", key: "Bengaluru" },
  { label: "Hyderabad", key: "Hyderabad" },
  { label: "Ahmedabad", key: "Ahmedabad" },
  { label: "Chennai", key: "Chennai" },
  { label: "Kolkata", key: "Kolkata" },
  { label: "Surat", key: "Surat" },
  { label: "Pune", key: "Pune" },
  { label: "Jaipur", key: "Jaipur" },
  { label: "Lucknow", key: "Lucknow" },
  { label: "Kanpur", key: "Kanpur" },
  { label: "Nagpur", key: "Nagpur" },
  { label: "Indore", key: "Indore" },
  { label: "Thane", key: "Thane" },
  { label: "Bhopal", key: "Bhopal" },
  { label: "Visakhapatnam", key: "Visakhapatnam" },
  { label: "Pimpri", key: "Pimpri" },
  { label: "Patna", key: "Patna" },
  { label: "Vadodara", key: "Vadodara" },
  { label: "Ghaziabad", key: "Ghaziabad" },
  { label: "Ludhiana", key: "Ludhiana" },
  { label: "Agra", key: "Agra" },
  { label: "Nashik", key: "Nashik" },
  { label: "Faridabad", key: "Faridabad" },
  { label: "Meerut", key: "Meerut" },
  { label: "Rajkot", key: "Rajkot" },
  { label: "Dombivli", key: "Dombivli" },
  { label: "Kalyan", key: "Kalyan" },
  { label: "Virar", key: "Virar" },
  { label: "Vasai", key: "Vasai" },
  { label: "Varanasi", key: "Varanasi" },
  { label: "Srinagar", key: "Srinagar" },
  { label: "Aurangabad", key: "Aurangabad" },
  { label: "Dhanbad", key: "Dhanbad" },
  { label: "Amritsar", key: "Amritsar" },
  { label: "Navi Mumbai", key: "Navi Mumbai" },
  { label: "Allahabad", key: "Allahabad" },
  { label: "Howrah", key: "Howrah" },
  { label: "Gwalior", key: "Gwalior" },
  { label: "Jabalpur", key: "Jabalpur" },
  { label: "Coimbatore", key: "Coimbatore" },
  { label: "Vijayawada", key: "Vijayawada" },
  { label: "Jodhpur", key: "Jodhpur" },
  { label: "Madurai", key: "Madurai" },
  { label: "Raipur", key: "Raipur" },
  { label: "Kota", key: "Kota" },
  { label: "Guwahati", key: "Guwahati" },
  { label: "Chandigarh", key: "Chandigarh" },
  { label: "Solapur", key: "Solapur" },
  { label: "Dharwad", key: "Dharwad" },
  { label: "Hubli", key: "Hubli" },
  { label: "Tiruchirappalli", key: "Tiruchirappalli" },
];
