import axios from "axios";
import { AUTH_KEY, AUTH_TOKEN_KEY } from "../constants/constant";
import { getParsedItem } from "../utils/Storage";
import { createAxiosInstance } from "./axiosInstances";

const BASE_URL = process.env.NEXT_PUBLIC_MASTER_URL;

const defaultHeaders = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
  "Content-Type": "application/json",
};

const defaultAxios = createAxiosInstance(BASE_URL);
export function apiClient({
  url,
  data = {},
  method = "GET",
  headers = {},
  noHeaders,
  ...rest
}) {
  const token = getParsedItem(AUTH_TOKEN_KEY);

  return new Promise((resolve, reject) => {
    defaultAxios({
      method,
      url,
      headers: {
        ...(noHeaders ? {} : defaultHeaders),
        ...headers,
        Authorization: token ? `Bearer ${token}` : undefined,
      },
      data,
      ...rest,
    })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
        }
        reject(err?.response || err);
      });
  });
}
