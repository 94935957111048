"use client";

import React from "react";
import LearningOne from "@/assets/images/learning_1.jpg";
import LearningTwo from "@/assets/images/learning_2.jpg";
import Image from "next/image";

const LearningCenter = () => {
  const cardsArray = Array.from(Array(2).keys());
  const learning = [
    {
      image: LearningOne,
      title: "Brand Learning Center",
      url: "brand-learning-centre",
      description:
        "Your go-to resource for mastering the art of franchising. Get insights, tips, and expert advice to make informed investment decisions.",
    },
    {
      image: LearningTwo,
      title: "Investor Learning Center",
      url: "investor-learning-centre",
      description:
        "Ultimate guide to mastering the franchise business. Learn from the experts and unlock your franchise's full potential",
    },
  ];

  return (
    <div className="bg-primary">
      <div className="container mx-auto px-4 py-3 lg:py-8">
        <div className="flex flex-wrap justify-between gap-11">
          <div className="w-full md:w-2/4">
            <h2 className="mb-3 text-lg md:text-2xl md:font-semibold lg:mb-8">
              Learning Center
            </h2>
          </div>
        </div>

        <div className="flex gap-4 md:gap-20">
          {learning.map((el, index) => (
            <div
              className="relative flex w-2/4 flex-col items-center rounded-lg rounded-s border border-gray-200 bg-white shadow hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 md:w-1/2  lg:flex-row"
              key={index}
            >
              <Image
                className="relative h-[170px] w-full max-w-[328px] rounded-t-lg object-cover  md:rounded-none md:rounded-s-lg"
                src={el.image}
                alt="image"
                fill={true}
              />
              <div className="flex flex-col justify-between p-4 leading-normal">
                <h6 className="mb-2 text-sm font-bold tracking-tight text-gray-900 dark:text-white md:text-base md:font-medium lg:text-base lg:font-medium">
                  {el?.title}
                </h6>
                <p className="mb-3 line-clamp-2 text-ellipsis text-xs font-normal text-gray-700 dark:text-gray-400 lg:text-sm">
                  {el?.description}
                </p>
                <div className="mt-2">
                  <a
                    href={el.url}
                    className=" inline-flex items-center justify-center text-sm font-medium text-primary hover:underline "
                  >
                    <span className="w-full">Know more</span>
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.48593 4.31527L11.1666 8.96786L6.51404 13.6486"
                        stroke="#1E7FD9"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LearningCenter;
