"use client";
import React from "react";
import {
  formatTotalInvestment,
  findMinMaxValues,
  createBrandDetailsPageSlug,
} from "@/utils/helper";
import Link from "next/link";
import ImageThumbnail from "@/components/common/ImageThumbnail/ImageThumbnail";

// Memoize component to prevent unnecessary re-renders
const FranchiseCard = React.memo(({ franchise }) => {
  const investedRange = findMinMaxValues([franchise], "totalinvestment");
  const areaRange = findMinMaxValues([franchise], "spacearea");

  return (
    <div className="relative mx-[10px] rounded-s border border-gray-200 bg-white shadow-xs md:mx-[18px]">
      {/* <div className="absolute left-2 top-2 z-10">
        <span className="rounded bg-secondary px-2 py-1 text-xxs font-medium  text-primary ">
          Restaurent
        </span>
      </div> */}
      <Link
        href={createBrandDetailsPageSlug(franchise?.brandname, franchise?._id)}
        className="relative"
      >
        <ImageThumbnail
          src={franchise?.brandlogo}
          alt="image"
          width={100}
          height={127}
          paddingBottom="50%"
        />
      </Link>
      <div className="pt-3">
        <div className="px-3 pb-3">
          <h5 className="mb-2 text-sm font-semibold tracking-tight text-color-grey">
            {franchise?.brandname}
          </h5>
          <div className="mb-1 flex justify-between">
            <p className="text-xs font-medium text-color-secondary-dark">
              Investment amount
            </p>
            <p className="text-xs font-medium text-color-secondary-dark">
              ₹{formatTotalInvestment(investedRange?.min)}
              {investedRange?.max > 0
                ? ` - ${formatTotalInvestment(investedRange?.max)}`
                : ""}
            </p>
          </div>
          <div className="mb-1 flex justify-between">
            <p className="text-xs font-medium text-color-secondary-dark">
              Area Req.
            </p>
            <p className="text-xs font-medium text-color-secondary-dark">
              {areaRange?.min}
              {areaRange?.max > 0 ? ` - ${areaRange?.max}` : ""}
            </p>
          </div>
          <div className="mb-1 flex justify-between">
            <p className="text-xs font-medium text-color-secondary-dark">
              Total franchise
            </p>
            <p className="text-xs font-medium text-color-secondary-dark">
              {franchise?.brandoutlets}
            </p>
          </div>
        </div>
        <Link
          href={createBrandDetailsPageSlug(
            franchise?.brandname,
            franchise?._id
          )}
          className="inline-flex h-[40px] w-full items-center justify-center rounded-bl rounded-br bg-tertiary px-3 py-2 text-sm font-medium text-color-black hover:bg-primary-color hover:text-white"
        >
          Know more
        </Link>
      </div>
    </div>
  );
});

export default FranchiseCard;
