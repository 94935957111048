"use client";
import { apiClient as userClient } from "@/config/userClient";
import { API_ENDPOINTS } from "@/constants/endpoints";

const { AUTH } = API_ENDPOINTS;

export const register = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.register();
      const res = await userClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const verifyOtp = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.verifyOtp();
      const res = await userClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const resendOtp = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.resendOtp();
      const res = await userClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const getProfile = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.getProfile();
      const res = await userClient({ url: url, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateProfile = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.updateProfile();
      const res = await userClient({ url: url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const postBillingDetails = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.saveBillingDetails();
      const res = await userClient({ url, method, data: payload });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};

export const refreshToken = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { url, method } = AUTH.refreshToken();
      const res = await userClient({ url: url, data: payload, method });
      resolve(res);
    } catch (error) {
      reject(error);
    }
  });
};
