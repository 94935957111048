"use client";

import React, { useRef, useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ChevronRight, ChevronLeft } from "react-feather";
import "@/components/common/Carousel/slider.scss";
import useResponsive from "@/hooks/useResponsive";

const HomepageCarousel = ({
  slidesToShow = 4,
  slidesToScroll = 4,
  autoplay = false,
  children,
  arrows = false,
  dots = false,
  infinite = false,
  title,
  subtitle,
  ...rest
}) => {
  //   const settings = {
  //     dots: false,
  //     infinite: false,
  //     speed: 500,
  //     slidesToShow: 4,
  //     slidesToScroll: 2,
  //     lazyLoad: true,
  //     swipeToSlide: false,
  //     arrows: false,
  //   };

  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [showArrows, setShowArrows] = useState(false);

  const { isMobile } = useResponsive();

  useEffect(() => {
    if (sliderRef) {
      // Assign the slider instance to the provided ref
      sliderRef.current = sliderRef.current;
    }
  }, [sliderRef]);
  useEffect(() => {
    //setTotalSlides(Math.ceil(children.length / slidesToScroll));
    setTotalSlides(children?.length || 0);
  }, [children.length, slidesToScroll]);

  useEffect(() => {
    setShowArrows(children?.length > slidesToShow);
  }, [children.length, slidesToShow]);

  //   console.log("totalSlides", totalSlides);
  //   console.log("children", children.length);
  //   console.log("currentSlide", currentSlide);

  const settings = {
    dots,
    infinite: infinite,
    speed: 500,
    slidesToShow,
    slidesToScroll,
    autoplay,
    autoplaySpeed: 2000,
    arrows,
    beforeChange: (current, next) => {
      //   console.log(current, next);
      setCurrentSlide(next);
    },
    afterChange: (current) => setCurrentSlide(current),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  return (
    <div className="slider__wrapper">
      <div className=" mb-3 w-full md:w-2/4 lg:mb-8">
        {subtitle && (
          <h6 className="mb-2 text-base font-medium text-color-danger md:mb-1">
            {subtitle}
          </h6>
        )}

        <div className="flex items-center">
          <h2 className=" mb-0 text-lg md:text-2xl md:font-semibold">
            {title}
          </h2>
          {showArrows && !isMobile && (
            <div className="slider__arrows ml-5 flex flex-1 pt-[8px]">
              <button
                onClick={handlePrev}
                type="button"
                className={`slider__arrow-button ${
                  currentSlide === 0 ? "arrow__disabled" : ""
                }`}
                style={{ marginRight: "10px" }}
                disabled={currentSlide === 0}
                aria-label="Previous"
              >
                <ChevronLeft />
              </button>
              <button
                onClick={handleNext}
                type="button"
                className={`slider__arrow-button ${
                  currentSlide >= totalSlides - slidesToScroll
                    ? "arrow__disabled"
                    : ""
                }`}
                disabled={currentSlide >= totalSlides - slidesToScroll}
                aria-label="Next"
              >
                <ChevronRight />
              </button>
            </div>
          )}
        </div>
      </div>

      {isMobile && (
        <div className="scrollbar-none mx-[-18px] flex flex-nowrap overflow-x-auto">
          {React.Children.map(children, (child) => (
            <div className="min-w-[15rem] max-w-[15rem]">{child}</div>
          ))}
        </div>
      )}

      {!isMobile && (
        <div className="mx-[-18px]">
          <Slider
            {...settings}
            ref={sliderRef}
            //onInit={() => setTotalSlides(children.length)}
          >
            {React.Children.map(children, (child) => (
              <>{child}</>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default HomepageCarousel;
