import React from "react";
import "./SkeletonCard.scss";

const SkeletonCard = ({
  mobileCount = 2,
  tabletCount = 3,
  desktopCount = 5,
  showCardTitle = true,
}) => {
  return (
    <div className="skeleton-loader">
      {showCardTitle && (
        <h2 className="skeleton-loader__title">
          <div className="skeleton-loader__animated-background"></div>
        </h2>
      )}

      <div className="skeleton-loader__cards">
        {[...Array(desktopCount)].map((_, index) => (
          <div
            key={index}
            className={`
            skeleton-loader__card
            ${index >= mobileCount ? "skeleton-loader__card--hide-mobile" : ""}
            ${index >= tabletCount ? "skeleton-loader__card--hide-tablet" : ""}
          `}
          >
            <div className="skeleton-loader__image">
              <div className="skeleton-loader__animated-background"></div>
            </div>
            <div className="skeleton-loader__content">
              <div className="skeleton-loader__text skeleton-loader__animated-background"></div>
              <div className="skeleton-loader__text skeleton-loader__animated-background"></div>
              <div className="skeleton-loader__text skeleton-loader__animated-background"></div>
            </div>
            <div className="skeleton-loader__button">
              <div className="skeleton-loader__animated-background"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkeletonCard;
